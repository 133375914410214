$header-height-mobile: 6.4rem;
$header-height-desktop: 12rem;

/****** MEGA MENU  *******/
$mega-model-container-space-top: 3.1rem;
$mega-menu-border-top-space: 3rem;
$mega-menu-title-size-key: medium;
$mega-menu-link-color: $black-ter;
$mega-menu-description-line-key: secondary;
$mega-menu-description-padding-top: 0.4rem;

/****** MEGA SIDE MENU (SHORTCUTS)  *******/
$mega-sidemenu-link-color: $brownish-grey;
$mega-sidemenu-label-size-key: navigation;
$mega-sidemenu-label-family-key: bold;
$mega-sidemenu-is-uppercase: false;
$mega-sidemenu-link-size-key: medium;

/****** NAVIGATION *******/
$mega-sidemenu-margin-top: 0.2rem;
$mega-sidemenu-link-padding-top: 0.3rem;
$mega-sidemenu-link-padding-bottom: 0.2rem;
$mega-sidemenu-link-icon-position: 10px;
$mega-sidemenu-link-text-position: 10px;

/****** MOBILE MENU *******/
$mobile-header-spacing-top: 3.2rem;
$mobile-header-spacing-bottom: 1rem;

$mobile-menu-link-color: $black-bis;
$mobile-menu-goto-color: $black;
$mobile-menu-goto-size-key: small;
$mobile-menu-goto-family-key: bold;
$mobile-menu-goto-padding-bottom: 1.6rem;

$mobile-menu-text-color: $black;

$mobile-menu-title-size-key: tiny;
$mobile-menu-title-family-key: bold;
$mobile-menu-title-line-height-key: large;
$mobile-menu-title-color: $black;
$mobile-menu-title-transform: uppercase;
$mobile-menu-title-font-weight-key: bold;
$mobile-menu-title-letter-spacing-key: base;

$mobile-holder-horizontal-padding: $gutter;

$mobile-nav-item-padding-left: 0;
$mobile-nav-item-padding-right: 0;
$mobile-nav-item-padding-top: 1.7rem;
$mobile-nav-item-padding-bottom: 2.3rem;

$mobile-chevron-x-cord: 4px;
$mobile-chevron-y-cord: 4px;

$mobile-nav-back-bg: transparent;
$mobile-nav-back-border-top: transparent;
$mobile-nav-back-border-bottom: transparent;
$mobile-nav-back-size-key: small;
$mobile-nav-back-color: $primary;
$mobile-nav-back-bottom: 1.6rem;

$mobile-breadcrumb-margin-left: -1.2rem;
$mobile-breadcrumb-padding-top: 0.6rem;

$mobile-links-color: $grey-darker;
$mobile-links-horizontal-padding: 0;
$mobile-links-vertical-padding: 2rem;
$mobile-links-icon-padding: 0.3rem;

/****** ICONS *******/
$icon-cross-size-small: 1.5rem;
$icon-cross-size-large: 3rem;

$icon-tag-is-circle: true;
$icon-tag-text-color: $black;
$icon-tag-border-radius-touch: 50%;
$icon-tag-border-radius-desktop: 50%;
$icon-font-size-touch-key: tiny;
$icon-tag-right: -1rem;
$icon-tag-top: -0.7rem;
$icon-tag-weight: 400;

/****** GRID *******/
$grid-first-row-padding-top: $gutter;
$grid-first-row-padding-touch-top: $gutter-small;
$dark-theme-bg: $grey-lighter;
$dark-theme-text: $text;
$light-theme-bg: $grey-lighter;
$light-theme-text: $text;
$dark-title-color: $text;
$dark-subtitle-color: $text;
$rte-dark-theme-text: $text;
$info-dark-color: $orange;
$grid-banner-row-has-bg: false;

/****** HEADLINE SPOT *******/
$light-subtitle-color: $text;
$headline-title-size-key-touch: large;
$headline-background-color: $grey;
$headline-background-color-light: $grey;

/****** CONTAINER GRID *******/
$container-padding-desktop: $gutter;
$container-padding-touch: $gutter-small;

/****** UTIL *******/
$border: $grey-light;

/****** RTE *******/
$rte-content-headline-padding-top: 1.5rem;
$rte-content-headline-margin-bottom: 1.2rem;

/****** FORM FIELD (INPUT, SELECT, LABEL ) *******/
$label-color: $text;
$label-text-transform: uppercase;
$label-font-size: $font-size-tiny;
$label-small-size-key: tiny;
$label-font-family: $font-family-base-bold;
$label-weight: map-get($font-weights, bold);
$help-text-size: sub;
$field-size-key: base;
$help-text-color: $grey-dark;
$consent-text-color: $grey-dark;
$custom-select-border-color: $border;
$custom-select-item-bg-hover: $grey-lighter;
$input-background-color: $grey;

/****** MAP SPOT *******/
$map-address-color: $grey-dark;
$map-address-line-height: 1.5;
$map-address-padding-top: 0.6rem;
$map-address-padding-bottom: 0.6rem;
$map-name-size-key: medium;

$map-opening-title-weight-key: bold;
$map-opening-title-size-key: x-small;
$map-opening-title-transform: uppercase;
$map-opening-title-color: $black-bis;
$map-opening-title-family-key: bold;
$map-opening-title-margin-bottom: 0;
$map-opening-title-padding-top: 2rem;
$map-opening-title-margin-bottom: 0;

$map-phone-title-display: block;
$map-phone-title-family-key: bold;
$map-phone-title-weight-key: bold;
$map-phone-title-size-key: x-small;
$map-phone-title-transform: uppercase;
$map-phone-title-color: $black-bis;
$map-phone-title-margin-bottom: 0;
$map-phone-title-padding-top: 2rem;
$map-phone-title-padding-bottom: 0.6rem;

$map-item-bg: $white;
$map-item-bg-active: rgba($black, 0.02);
$map-item-bg-hover: rgba($black, 0.04);

$map-list-bg: $white;
$map-has-border: true;
$map-button-search-min-width: auto;
$map-result-padding: 0;
$map-toggle-button-color: $grey-darker;

/****** HEADLINE SPOT *******/
$default-subtitle-color: $grey-dark;
$headline-subtitle-touch-font-key: medium;
$headline-subtitle-desktop-font-key: medium;

/****** BUTTONS *******/
$button-secondary-theme: 'filled';
$button-border-radius: 2.5rem;
$button-font-weight: $font-weight-secondary;
$button-border-padding: 0.5rem 1.4rem;
$selected-filter-btn-padding-left: 1.4rem;
$selected-filter-btn-color: $green-dark;

// Primary/FILLED button in default row theme
$button-primary-color: $black;
$button-primary-bg: $primary;
$button-primary-hover-bg: $hover;
$button-primary-active-bg: $hover;
$button-primary-text-hover-color: $black;
$button-primary-border-color: $primary;
$button-primary-border-hover-color: $hover;
// Filled button in dark row theme
$button-filled-dark-text-color: $black;
$button-filled-dark-bg: transparent;
$button-filled-dark-bg-hover: $primary;
$button-filled-dark-bg-active: $primary;
$button-filled-dark-text-hover-color: $black;
$button-filled-dark-border-color: $primary;
$button-filled-dark-border-hover-color: $primary;
// // Filled button in light row theme
$button-filled-light-text-color: $black;
$button-filled-light-bg: $primary;
$button-filled-light-bg-hover: $hover;
$button-filled-light-bg-active: $hover;
$button-filled-light-text-hover-color: $black;
$button-filled-light-border-color: $primary;
$button-filled-light-border-hover-color: $hover;
// button outline in default row theme
$button-outline-text-color: $black;
$button-outline-text-hover-color: $black;
$button-outline-bg: transparent;
$button-outline-bg-hover: $hover;
$button-outline-bg-active: $hover;
$button-outline-border-color: $primary;
$button-outline-border-hover-color: $hover;
// Outline button in light row theme
$button-outline-light-text-color: $black;
$button-outline-light-text-hover-color: $black;
$button-outline-light-bg: transparent;
$button-outline-light-bg-hover: $primary;
$button-outline-light-bg-active: $primary;
$button-outline-light-border-color: $primary;
// Outline button in dark row theme
$button-outline-dark-text-color: $black;
$button-outline-dark-text-hover-color: $black;
$button-outline-dark-bg: transparent;
$button-outline-dark-bg-hover: $white;
$button-outline-dark-bg-active: $white;
$button-outline-dark-border-color: $white;
// Underline button
$button-underlined-color: $black;
$button-underlined-bg: $primary;
$button-underlined-size: $font-size-navigation;
// Secondary button
$button-secondary-color: $green-dark;
$button-secondary-bg: $white;
$button-secondary-hover-bg: $grey-light;
$button-secondary-active-bg: $grey-light;
$button-secondary-text-hover-color: $primary;
$button-secondary-border-color: $white;
$button-secondary-border-hover-color: $grey-light;
// Product sorting button
$button-product-sorting-color: $white;

$button-close-bg-hover: darken($primary, 10%);

$button-link-text: $green-dark;
$button-link-text-hover: darken($green-dark, 20%);

$button-line-height-key: normal;

/****** PRODUCT ITEM *******/
$product-item-title-padding: 1rem;
$product-item-tag-primary-bg: $black-ter;
$product-item-tag-secondary-bg: #da2c38;
$product-item-tags-weight: $font-weight-bold;
$product-item-details-line-height: 1.5;
$product-item-primary-default-size-key: medium;
$product-item-primary-desktop-size-key: largeMobile;
$product-item-primary-line-height-key: secondary;
$product-item-price-padding: 0.7rem 1.2rem 1.1rem 2rem;

$product-last-viewed-price-size-desktop: $product-item-primary-desktop-size-key;

/****** PAGE TRANSITION *******/
$page-transition-bg: $grey;
$page-transition-text: $black-bis;

/****** HEADLINE *******/
$headline-title-font-weight: $font-weight-light;

/****** HERO *******/
$hero-title-text-transform: uppercase;
$hero-title-font-weight: $font-weight-bold;
$hero-title-margin-bottom: 0.4rem;
$hero-title-line-height-key: tiny;
$hero-subtitle-size-key: largeMobile;
$hero-subtitle-family-key: base;
$hero-subtitle-line-height-key: normal;
$hero-subtitle-weight-key: normal;
$hero-subtitle-weight-key: normal;

/****** LINK TAG *******/
$link-default-color: $green-dark;
$link-default-color-hover: darken($green-dark, 20%);
$link-default-decoration: underline;

/****** CAMPAIGN SPOT *******/
$campaigns-spot-content-color: $black;
$campaigns-spot-title-padding: 0.4rem;

/****** MODEL PAGE *******/
$sub-nav-header-bg: $white;
$sub-nav-header-color: $text;
$model-nav-active-color: $primary;
$sub-nav-header-border-width: 1px;
$model-page-title-is-small: true;
$model-nav-item-size-key: navigation;

/****** SLIME *******/
$slime-font-size: largeMobile;
$slime-font-family: base;
$slime-font-line-height: secondary;
$slime-font-weight: normal;
$slime-font-size: navigation;
$slime-small-font-size: base;
$slime-small-font-family: base;

/****** PRODUCT DETAILS *******/
$product-details-description-height-desktop: 9rem;
$product-details-description-height-touch: 9.2rem;
$product-details-link-color-dark: $green-dark;
$product-details-tag-margin: 1rem;

/****** FILTER *******/
$product-sorting-amount-size-key: navigation;
$product-sorting-amount-touch-size-key: medium;

/****** IMAGE TEXT SPOT *******/
$image-text-item-title-line-key: small;
$icon-font-size-desktop-key: tiny;

$slime-border-color: $border;

/****** ASIDE *******/
$aside-header-title-size: 4rem;
$aside-header-title-margin-bottom: 0;

/****** SIDE MENU *******/
$side-menu-label-letter-key: base;

/****** LEGAL *******/
$legal-line-height: 1.2;

/****** CTA SPOT *******/
$cta-link-hover-color: $hover;
$cta-link-hover-color--theme-dark: $hover;
$cta-link-line-height: 3.5rem;
$cta-image-filter--theme-dark: none;
$cta-divider-color: $grey;
$cta-divider-color--theme-dark: $grey-dark;

/****** BREADCRUMB INDENTS *******/
$breadcrumb-indent-mobile: 5px;
$breadcrumb-indent-mobile-small: 5px;
$breadcrumb-indent-tablet: 5px;
$breadcrumb-indent-desktop: 0;
$breadcrumb-indent-widescreen: 0;
$breadcrumb-indent-fullhd: 0;
$breadcrumb-indent-fullwidth: 0;
